import { Inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { catchError, tap, timeout } from 'rxjs/operators';
import { RegistrationService } from '../services/registration.service';
import { REGISTRATION_STATE_DEFAULTS } from './registration-state-defaults.const';
import { IRegistrationState } from './registration-state.interface';
import { RegistrationActions } from './registration.actions';
import { BRAND_WEBBANKIR } from '@app/constants/brand-webbankir.const';
import { ISdsDto } from '@app/states/registration/interfaces/sds-dto.interface';
import { IConsent } from '@app/interfaces/consent.interface';
import {
  IRegistrationApplicationDto,
} from '@app/states/registration/interfaces/registration-application-dto.interface';
import { IRegistrationS1Dto } from '@app/states/registration/interfaces/registration-s1-dto.interface';
import { IRegistrationS2Dto } from '@app/states/registration/interfaces/registration-s2-dto.interface';
import { IRegistrationS3Dto } from '@app/states/registration/interfaces/registration-s3-dto.interface';
import { ToastService } from '@web-bankir/ui-kit/components/toast';
import { CookieService } from '@app/services/cookie/cookie.service';
import { ErrorCode } from '@app/constants/error-code.const';
import { AuthActions } from '@app/states/auth/states/auth.actions';
import { EventsState } from '@app/states/events/states/events.state';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { EventsActions } from '@app/states/events/states/events.actions';
import { EventName } from '@app/states/events/constants/event-name.const';
import { StateErrorHandler } from '@app/helpers/abstractions/state-error-handler.class';
import { isLoginDataResponse } from '@app/type-guards/login-response.type-guard';
import { Navigate } from '@ngxs/router-plugin';
import { DeviceInfoService } from '@app/services/device-info/device-info.service';
import { CategoryName } from '@app/states/events/constants/category-name.const';
import { forkJoin, throwError } from 'rxjs';
import { MetricType } from '@app/states/events/constants/metric-type.const';
import { JuicyscoreService } from '@app/services/juicyscore/juicyscore.service';
import { v4 as uuid } from 'uuid';
import { ICustomerProfile } from '@app/states/events/interfaces/mindbox/customer-profile.interface';
import { MindboxOperation } from '@app/states/events/constants/mindbox-operation.const';
import { ConsentService } from '@app/services/consent/consent.service';
import { IProfileForm } from '@app/states/profile/interfaces/profile-form.interface';
import { ProfileState } from '@app/states/profile/states/profile.state';
import dayjs from 'dayjs';
import { IRegistrationIdentification } from '../interfaces/registration-identification.interface';

@State<IRegistrationState>({
  name: 'registration',
  defaults: REGISTRATION_STATE_DEFAULTS,
})

/**
 * Класс NGXS состояния регистрации пользователя
 */
@Injectable()
export class RegistrationState extends StateErrorHandler {

  /**
   * Конструктор класса состояния регистрации пользователя
   *
   * @param store NGXS хранилище
   * @param service [Сервис]{@link RegistrationService} регистрации пользователя
   * @param deviceInfoService [Сервис]{@link DeviceInfoService} информации о девайсе с которого происходит регистрация
   * @param toast [Сервис]{@link ToastService} библиотеки ui-kit всплывающих информационных окон
   * @param cookie [Сервис]{@link CookieService} для работы с cookies
   * @param window [Интерфейс]{@link Window} для работы с окном браузера и DOM
   * @param juicyScore [Сервис]{@link JuicyscoreService} API Juicyscore (риск-менеджмент)
   * @param consentsService [Сервис]{@link ConsentService} преоброзования данных
   */
  constructor(
    protected store: Store,
    private service: RegistrationService,
    private deviceInfoService: DeviceInfoService,
    protected toast: ToastService,
    @Inject('Window') public window: Window,
  ) {
    super(toast, store);
  }

  /**
   * Селектор формы первого шага регистрации
   *
   * @param state состояние регистрации пользователя
   *
   * Возвращает сформированный объект интерфейса {@link IRegistrationS1Dto} содержащий введенные
   * пользователем данные первого шага регистрации
   */
  @Selector([ProfileState.form])
  public static stepOne(state: IRegistrationState, form: IProfileForm) {
    return this.buildStepOne(form);
  }

  /**
   * Селектор формы второго шага регистрации
   *
   * @param state состояние регистрации пользователя
   *
   * Возвращает сформированный объект интерфейса {@link IRegistrationS2Dto} содержащий введенные
   * пользователем данные второго шага регистрации
   */
  @Selector([ProfileState.form])
  public static stepTwo(state: IRegistrationState, form: IProfileForm) {
    return this.buildStepTwo(form);
  }

  /**
   * Селектор формы третьего шага регистрации
   *
   * @param state состояние регистрации пользователя
   *
   * Возвращает сформированный объект интерфейса {@link IRegistrationS3Dto} содержащий введенные
   * пользователем данные третьего шага регистрации
   */
  @Selector([ProfileState.form])
  public static stepThree(state: IRegistrationState, form: IProfileForm) {
    return this.buildStepThree(form);
  }

  /**
   * Селектор формы регистрации
   *
   * @param state состояние регистрации пользователя
   * @param webbankirCrossId WEBBANKIR Cross ID
   *
   * Возвращает сформированный объект интерфейса {@link IRegistrationApplicationDto} содержащий
   * введенные пользователем данные формы регистрации
   */
  @Selector([RegistrationState, EventsState.webbankirCrossId, ProfileState.form])
  public static form( state: IRegistrationState, webbankirCrossId: string, form: IProfileForm) {
    return this.buildForm(state, form, webbankirCrossId);
  }

  /**
   * Селектор возвращающий информацию о использовании Госуслуг для регистрации
   * @param state состояние регистрации пользователя
   */
  @Selector()
  public static esiaNotUsed(state: IRegistrationState) {
    return !state.esiaUsed;
  }

  /**
   * Действие состояния отражающее успешное прохождения регистрации.
   *
   * Порядок выполнения:
   * - [Сброс]{@link RegistrationState#reset} состояния регистрации (очистка формы)
   * - Вызов {@link AuthActions#LoginSuccess} для установки флага регистрации в false
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.Complete)
  public complete(ctx: StateContext<IRegistrationState>,
                  action: RegistrationActions.Complete) {
    ctx.dispatch(new RegistrationActions.Reset());
    const webbankirCrossId = this.store.selectSnapshot(EventsState.webbankirCrossId);
    const { token, tokenExpired, userId } = this.store.selectSnapshot(s => s.Auth);
    ctx.dispatch(new AuthActions.LoginSuccess({token, tokenExpired, userId, webbankirCrossId}));
  }

  @Action(RegistrationActions.InitSession)
  public initSession(ctx: StateContext<IRegistrationState>,
                     action: RegistrationActions.InitSession) {
    const webbankirCrossId = this.store.selectSnapshot(EventsState.webbankirCrossId);

    return this.service.submit({ webbankirCrossId }).pipe(
      tap(() => ctx.dispatch(new RegistrationActions.InitSessionSuccess())),
      catchError((err, caught) => {
        this.catchSentryError('RegistrationActions.InitSessionFail', err);
        return ctx.dispatch(
          new RegistrationActions.InitSessionFail(err.error?.errors || []),
        );
      }),
    );
  }

  @Action(RegistrationActions.InitSessionFail)
  public initSessionFail(ctx: StateContext<IRegistrationState>,
                         action: RegistrationActions.InitSessionFail) {
    ctx.dispatch(new EventsActions.SetWebbankirCrossId(uuid()));
    ctx.dispatch(new RegistrationActions.InitSession());
  }

  @Action(RegistrationActions.InitSessionSuccess)
  public initSessionSuccess(ctx: StateContext<IRegistrationState>,
                            action: RegistrationActions.InitSessionSuccess) {

  }

  /**
   * Действие предзаполнения формы регистрации
   *
   * Порядок выполнения:
   * - Проверка был ли пропущен первый шаг регистрации, если да то первый шаг не предустанавливается, если нет -
   * заполнение данными;
   * - [Обновление]{@link UpdateFormValue} данных в форме регистрации
   * - [Вызов события]{@link EventsActions#Event} регистрации ESIA
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.Fill)
  public fill(ctx: StateContext<IRegistrationState>,
              action: RegistrationActions.Fill) {
    const form = this.store.selectSnapshot(ProfileState.form);
    const stepOne = action.skipFirstStep
      ? {
        bDay: form?.bDay,
        fio: form?.fio,
        phone: form?.phone}
      : {
        fio: [action.payload.lastName, action.payload.firstName, action.payload.middleName].join(' '),
        bDay: action.payload.bDay,
        phone: action.payload.mobilePhone ? `${action.payload.mobilePhone}`.substring(1) : null,
      };

    const normalizeInn = this.normalizeInnOrSnils(action.payload.inn, 'inn');
    const normalizeSnils = this.normalizeInnOrSnils(action.payload.snils, 'snils');

    ctx.dispatch(new UpdateFormValue({
      path: 'profile.form',
      value: {
        ...stepOne,
        confirmation: form?.confirmation,
        email: action.payload.email,
        bPlace: action.payload.bPlace,
        gender: action.payload.gender,
        passport: action.payload.passport,
        passportDivisionCode: action.payload.passportDivisionCode,
        passportDateOfIssue: action.payload.passportDateOfIssue,
        passportIssuedBy: action.payload.passportIssuedBy,
        passportEsiaStatus: action.payload.passportEsiaStatus,
        address: action.payload.address ? {
          value: {
            postal_code: action.payload.address.postalCode,
            region_kladr_id: action.payload.address.region,
            city: action.payload.address.city,
            settlement: action.payload.address.settlement,
            street: action.payload.address.street,
            street_with_type: action.payload.address.street,
            house: action.payload.address.house,
            geo_lat: action.payload.address.geoLat,
            geo_lon: action.payload.address.geoLon,
            block: [action.payload.address.housing, action.payload.address.building].filter(e => !!e).join(' стр '),
            block_type: (action.payload.address.housing ? 'к' : (action.payload.address.building ? 'стр' : null)),
            flat: action.payload.address.flat,
            region_fias_id: action.payload.addressFiases.region_fias_id,
            city_fias_id: action.payload.addressFiases.city_fias_id,
            settlement_fias_id: action.payload.addressFiases.settlement_fias_id,
            street_fias_id: action.payload.addressFiases.street_fias_id,
            house_fias_id: action.payload.addressFiases.house_fias_id,
          },
          label: action.payload.addressFiases.value,
        } : null,
        addressRegDate: action.payload.addressRegDate,
        doNotHaveStreet: action.payload.addressFiases ? !action.payload.addressFiases.street_fias_id : null,
        doNotHaveFlat: action.payload.address ? !action.payload.address.flat : null,
        snilsOrInn: this.normalizeInnOrSnils(action.payload.snilsOrInn) || normalizeInn || normalizeSnils,
        inn: normalizeInn,
        snils: normalizeSnils,
        typeOfEmployment: action.payload.typeOfEmployment ? { value: action.payload.typeOfEmployment } : null,
        workSalary: action.payload.workSalary,
        workType: action.payload.workType,
        workOgrnip: action.payload.workOgrnip,
        workRegDate: action.payload.workRegDate,
        workScope: action.payload.workScope ? { value: action.payload.workScope } : null,
        workNumberOfEmployees: action.payload.workNumberOfEmployees ? { value: action.payload.workNumberOfEmployees } : null,
        workPeriod: action.payload.workPeriod ? { value: action.payload.workPeriod } : null,
        workName: action.payload.workName,
        workAddress: action.payload.workAddress ? {
          value: {
            postal_code: action.payload.workAddress.postalCode,
            region_kladr_id: action.payload.workAddress.region,
            city: action.payload.workAddress.city,
            settlement: action.payload.workAddress.settlement,
            street: action.payload.workAddress.street,
            house: action.payload.workAddress.house,
            geo_lat: action.payload.workAddress.geoLat,
            geo_lon: action.payload.workAddress.geoLon,
            block: [action.payload.workAddress.housing, action.payload.workAddress.building].filter(e => !!e).join(' стр '),
            block_type: (action.payload.workAddress.housing ? 'к' : (action.payload.workAddress.building ? 'стр' : null)),
            flat: action.payload.workAddress.flat,
            region_fias_id: action.payload.workAddressFiases.region_fias_id,
            city_fias_id: action.payload.workAddressFiases.city_fias_id,
            settlement_fias_id: action.payload.workAddressFiases.settlement_fias_id,
            street_fias_id: action.payload.workAddressFiases.street_fias_id,
            house_fias_id: action.payload.workAddressFiases.house_fias_id,
          },
          label: action.payload.workAddressFiases.value,
        } : null,
        maritalStatus: action.payload.maritalStatus ? { value: action.payload.maritalStatus } : null,
        hasChildren: action.payload.children,
        numberOfChildren: action.payload.children ? action.payload.numberOfChildren : 0,
        educationType: action.payload.educationType ? { value: action.payload.educationType } : null,
        workFullName: action.payload.workFullName,
        workInn: action.payload.workINN,
        workPhone: action.payload.workPhone,
        workPosition: action.payload.workPosition,
      },
    }));

    ctx.dispatch(new EventsActions.Event({
      name: EventName.Esia,
      category: CategoryName.Registration,
      payload: { phone: `${action.payload.mobilePhone}` },
    }));
  }

  /**
   * // TODO удалить, не используется
   * Действие получения согласий пользователя
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link RegistrationService#consents}
   * - В случае успешного ответа - передаем [данные]{@link RegistrationState#consentsSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link RegistrationState#consentsFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.Consents)
  public consents(ctx: StateContext<IRegistrationState>,
                  action: RegistrationActions.Consents) {
    const deviceInfo = this.deviceInfoService.info;
    return forkJoin(action.payload.map(consent => this.service.consents(consent, deviceInfo)))
      .pipe(
        tap((response) => {
          const flatResponse = response.map(item => item.data).flat();
          ctx.dispatch(new RegistrationActions.ConsentsSuccess(flatResponse, deviceInfo));
        }),
        catchError((err, caught) => {
          this.catchSentryError('RegistrationActions.ConsentsFail', err);
          return ctx.dispatch(
            new RegistrationActions.ConsentsFail(err.error?.errors),
          );
        }),
      );
  }

  /**
   * // TODO удалить, не используется
   * Действие неуспешного получения согласий пользователя
   *
   * {@link RegistrationState#consents}
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.ConsentsFail)
  public consentsFail(ctx: StateContext<IRegistrationState>,
                      action: RegistrationActions.ConsentsFail) {
    this.catchError(action.payload);
  }

  /**
   * // TODO удалить, не используется
   * Действие успешного получения согласий пользователя
   *
   * {@link RegistrationState#consents}
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.ConsentsSuccess)
  public consentsSuccess(ctx: StateContext<IRegistrationState>,
                         action: RegistrationActions.ConsentsSuccess) {
  }

  /**
   * Действие отправки первого шага формы заполнения
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link RegistrationService#submit}
   * - В случае успешного ответа - передаем [данные]{@link RegistrationActions#SubmitStepOne} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link RegistrationActions#SubmitStepOneFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SubmitStepOne)
  public submitStepOne(ctx: StateContext<IRegistrationState>,
                       action: RegistrationActions.SubmitStepOne) {
    return this.service.submit(RegistrationState.buildStepOne(this.store.selectSnapshot(ProfileState.form))).pipe(
      tap(() =>
        ctx.dispatch(new RegistrationActions.SubmitStepOneSuccess())),
      catchError((err, caught) => {
        this.catchSentryError('RegistrationActions.SubmitStepOneFail', err);
        return ctx.dispatch(
          new RegistrationActions.SubmitStepOneFail(err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие при неуспешной отправки первого шага формы заполнения
   *
   * Порядок выполнения:
   * - Проверка является ли ошибка {@link MobilePhoneNotUnique}, если true добавляется в текст ошибки ссылка
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SubmitStepOneFail)
  public submitStepOneFail(ctx: StateContext<IRegistrationState>,
                           action: RegistrationActions.SubmitStepOneFail) {
    // TODO модефицировать текст ошибки на бэке, чтобы приходила гиперссылка
    const errors = action.payload?.map((err) => {
      if (err.code === ErrorCode.MobilePhoneNotUnique) {
        const arrayMessage = err.message.split(' ');
        const url = arrayMessage.pop();
        const errorLink = this.convertStringToLink(url);
        return {
          ...err,
          message: `${arrayMessage.join(' ')} ${errorLink.outerHTML}`,
        };
      } else {
        return err;
      }
    });
    this.catchError(errors);
  }

  /**
   * Действие при успешной отправки первого шага формы заполнения
   *
   * Порядок выполнения:
   * - Обновление в состоянии отметки времени начала процесса регистрации
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SubmitStepOneSuccess)
  public submitStepOneSuccess(ctx: StateContext<IRegistrationState>,
                              action: RegistrationActions.SubmitStepOneSuccess) {
    ctx.setState(
      patch({
        currentStep: 'confirmation',
        registrationStartTimestamp: new Date().getTime(),
      }),
    );
  }

  /**
   * Действие верификации данных формы регистрации
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link RegistrationService#verify}
   * - В случае успешного ответа - передаем [данные]{@link RegistrationActions#VerifySuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link RegistrationActions#VerifyFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.Verify)
  public verify(ctx: StateContext<IRegistrationState>,
                action: RegistrationActions.Verify) {
    return this.service.verify(action.payload).pipe(
      tap(() => ctx.dispatch(new RegistrationActions.VerifySuccess(action.field))),
      catchError((err, caught) => {
        this.catchSentryError('RegistrationActions.VerifyFail', err);
        return ctx.dispatch(
          new RegistrationActions.VerifyFail(action.field, err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие при неуспешной верификации данных формы регистрации
   *
   * Порядок выполнения:
   * - Проверка является ли ошибка {@link UserFioAndBirthDayNotUnique} или {@link UserPassportDataNotUnique},
   * если true добавляется в текст ошибки ссылка;
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.VerifyFail)
  public verifyFail(ctx: StateContext<IRegistrationState>,
                    action: RegistrationActions.VerifyFail) {
    // TODO модефицировать текст ошибки на бэке, чтобы приходила гиперссылка
    const errors = action.payload?.map((err) => {
      if (err.code === ErrorCode.UserFioAndBirthDayNotUnique || err.code === ErrorCode.UserPassportDataNotUnique) {
        const arrayMessage = err.message.split(' ');
        const url = arrayMessage.pop();
        const errorLink = this.convertStringToLink(url);
        return {
          ...err,
          message: `${arrayMessage.join(' ')} ${errorLink.outerHTML}`,
        };
      } else {
        return err;
      }
    });
    this.catchError(errors);
  }

  /**
   * Действие при успешной верификации данных формы регистрации
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.VerifySuccess)
  public vefirySuccess(ctx: StateContext<IRegistrationState>,
                       action: RegistrationActions.VerifySuccess) {
  }

  /**
   * Действие для предлагаемых вариантов заполнения данных формы регистрации
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link RegistrationService#suggestion}
   * - В случае успешного ответа - передаем [данные]{@link RegistrationActions#SuggestionSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link RegistrationActions#SuggestionFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.Suggestion)
  public suggestion(ctx: StateContext<IRegistrationState>,
                    action: RegistrationActions.Suggestion) {
    return this.service.suggestion(action.payload.type, action.payload.value).pipe(
      tap((response) =>
        ctx.dispatch(new RegistrationActions.SuggestionSuccess(response.result?.map(
            (item) => ({
              value: item.data,
              label: item.value,
            })),
          action.payload.type,
        )),
      ),
      catchError((err, caught) => {
        this.catchSentryError('RegistrationActions.SuggestionFail', err);
        return ctx.dispatch(
          new RegistrationActions.SuggestionFail(err.error?.errors || [], action.payload.type),
        );
      }),
    );
  }

  /**
   * Действие при форматировании ФИО в правильный порядок: Фамилия, Имя, Отчество формы регистрации
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link RegistrationService#buildFio}
   * - В случае успешного ответа - передаем [данные]{@link RegistrationActions#BuildFioSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link RegistrationActions#BuildFioFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.BuildFio)
  public buildfio(ctx: StateContext<IRegistrationState>,
                  action: RegistrationActions.BuildFio) {
    return this.service.buildFio(action.payload.value).pipe(
      tap((response) => {
          ctx.dispatch(new RegistrationActions.BuildFioSuccess(
            {
              value: response.result.result,
            },
            action.payload.type,
          ));
        },
      ),
      catchError((err, caught) => {
        this.catchSentryError('RegistrationActions.BuildFioFail', err);
        return ctx.dispatch(
          new RegistrationActions.BuildFioFail(err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие при успешном форматировании ФИО в правильный порядок: Фамилия, Имя, Отчество формы регистрации
   *
   * Порядок выполнения:
   * - Обновление ФИО в форме регистрации
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.BuildFioSuccess)
  public buildfioSuccess(ctx: StateContext<IRegistrationState>,
                         action: RegistrationActions.BuildFioSuccess) {
    ctx.dispatch(new UpdateFormValue({
      path: 'profile.form',
      value: {
        ...this.store.selectSnapshot(ProfileState.form),
        fio: action.payload.value,
      },
    }));
  }

  /**
   * Действие при неуспешном форматировании ФИО в правильный порядок: Фамилия, Имя, Отчество формы регистрации
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SuggestionFail)
  public addressFail(ctx: StateContext<IRegistrationState>,
                     action: RegistrationActions.SuggestionFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие отправки второго шага формы заполнения
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link RegistrationService#submit}
   * - В случае успешного ответа - передаем [данные]{@link RegistrationActions#SubmitStepTwoSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link RegistrationActions#SubmitStepTwoFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SubmitStepTwo)
  public submitStepTwo(ctx: StateContext<IRegistrationState>,
                       action: RegistrationActions.SubmitStepTwo) {
    return this.service.submit(RegistrationState.buildStepTwo(this.store.selectSnapshot(ProfileState.form))).pipe(
      tap(() =>
        ctx.dispatch(new RegistrationActions.SubmitStepTwoSuccess())),
      catchError((err, caught) => {
        this.catchSentryError('RegistrationActions.SubmitStepTwoFail', err);
        return ctx.dispatch(
          new RegistrationActions.SubmitStepTwoFail(err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие при неуспешной отправки второго шага формы заполнения
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SubmitStepTwoFail)
  public submitStepTwoFail(ctx: StateContext<IRegistrationState>,
                           action: RegistrationActions.SubmitStepTwoFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие при успешной отправки второго шага формы заполнения
   *
   * Порядок выполнения:
   * - Обновление в состоянии наименование шага регистрации
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SubmitStepTwoSuccess)
  public submitStepTwoSuccess(ctx: StateContext<IRegistrationState>,
                              action: RegistrationActions.SubmitStepTwoSuccess) {
    ctx.setState(
      patch({
        currentStep: '3',
      }),
    );
  }

  /**
   * Действие для получения ИНН пользователя для предзаполнения формы регистрации по установочным данным
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link RegistrationService#getInn}
   * - В случае успешного ответа - передаем [данные]{@link RegistrationActions#InnSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link RegistrationActions#InnFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.Inn)
  public inn(ctx: StateContext<IRegistrationState>,
             action: RegistrationActions.Inn) {
    return this.service.getInn(this.store.selectSnapshot(EventsState.webbankirCrossId)).pipe(
      tap((response) =>
        ctx.dispatch(new RegistrationActions.InnSuccess(response.data?.[0]?.attributes.inn)),
      ),
      catchError((err, caught) => {
        this.catchSentryError('RegistrationActions.InnFail', err);
        return ctx.dispatch(
          new RegistrationActions.InnFail(err.error?.errors),
        );
      }),
    )
  }

  /**
   * Действие для неуспешного получения ИНН пользователя для предзаполнения формы регистрации по установочным данным
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.InnFail)
  public innFail(ctx: StateContext<IRegistrationState>,
                 action: RegistrationActions.InnFail) {

  }

  /**
   * Действие для успешного получения ИНН пользователя для предзаполнения формы регистрации по установочным данным
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.InnSuccess)
  public innSuccess(ctx: StateContext<IRegistrationState>,
                    action: RegistrationActions.InnSuccess) {

  }

  /**
   * Действие отправки третьего шага формы заполнения
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link RegistrationService#submit}
   * - В случае успешного ответа - передаем [данные]{@link RegistrationActions#SubmitStepThreeSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link RegistrationActions#SubmitStepThreeFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SubmitStepThree)
  public submitStepThree(ctx: StateContext<IRegistrationState>,
                         action: RegistrationActions.SubmitStepThree) {
    const webbankirCrossId = this.store.selectSnapshot(EventsState.webbankirCrossId);
    return this.service.submitThreeStep(RegistrationState.buildForm(
      ctx.getState(),
      this.store.selectSnapshot(ProfileState.form),
      webbankirCrossId,
    ))
      .pipe(
        tap((response) => {
          ctx.dispatch(new EventsActions.AddMetric({ status_code: response.status, action: MetricType.CreateUser }));
          ctx.dispatch(new RegistrationActions.SubmitStepThreeSuccess({...response.body.data, webbankirCrossId}));
        }),
        catchError((err, caught) => {
          this.catchSentryError('RegistrationActions.SubmitStepThreeFail', err);
          ctx.dispatch(new EventsActions.AddMetric({ status_code: err.status, action: MetricType.CreateUser }));
          ctx.dispatch(new RegistrationActions.SubmitStepThreeFail(err.error?.errors));
          return throwError(() => err);
        }),
      );
  }

  /**
   * Действие при неуспешной отправки третьего шага формы заполнения
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SubmitStepThreeFail)
  public submitStepThreeFail(ctx: StateContext<IRegistrationState>,
                             action: RegistrationActions.SubmitStepThreeFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие при успешной отправки третьего шага формы заполнения
   *
   * Порядок выполнения:
   * - Обновление в состоянии наименование шага регистрации
   * - Обращение к [действию]{@link RegistrationActions#FillAdditional} для отправки дополнительных полей при регистрации
   * - Обращение к [действию]{@link ProfileActions#Load} для загрузки профайла пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SubmitStepThreeSuccess)
  public submitStepThreeSuccess(ctx: StateContext<IRegistrationState>,
                                action: RegistrationActions.SubmitStepThreeSuccess) {
    ctx.setState(
      patch({
        currentStep: '4',
      }),
    );

    ctx.dispatch(new AuthActions.LoginSuccess({...action.payload, registration: true}));
    ctx.dispatch(new RegistrationActions.FillAdditional());
  }

  /**
   * Действие для поля смс-кода формы регистрации
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link RegistrationService#code}
   * - В случае успешного ответа - передаем [данные]{@link RegistrationActions#SmsCodeSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link RegistrationActions#SmsCodeFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SmsCode)
  public smsCode(ctx: StateContext<IRegistrationState>,
                 action: RegistrationActions.SmsCode) {
    const { phone } = this.store.selectSnapshot(ProfileState.form);
    return this.service.code({
      type: 'PhoneVerification',
      attributes: {
        phone: '7' + phone,
        webbankirCrossId: this.store.selectSnapshot(EventsState.webbankirCrossId),
      },
    }).pipe(
      tap((response) => ctx.dispatch(new RegistrationActions.SmsCodeSuccess(response.data))),
      catchError((err, caught) => {
        this.catchSentryError('RegistrationActions.SmsCodeFail', err);
        return ctx.dispatch(
          new RegistrationActions.SmsCodeFail(err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие для неуспешного ввода смс-кода формы регистрации
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SmsCodeFail)
  public smsCodeFail(ctx: StateContext<IRegistrationState>,
                     action: RegistrationActions.SmsCodeFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешного ввода смс-кода формы регистрации
   *
   * Порядок выполнения:
   * - Обновление в состоянии данных о проверочном смс-коде
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SmsCodeSuccess)
  public smsCodeSuccess(ctx: StateContext<IRegistrationState>,
                        action: RegistrationActions.SmsCodeSuccess) {
    ctx.dispatch(new UpdateFormValue({
      path: 'profile.form',
      value: {
        ...this.store.selectSnapshot(ProfileState.form)?.confirmation,
        verificationCodeId: action.payload.id,
      },
      propertyPath: 'confirmation',
    }));
  }

  /**
   * Действие для изменения телефона пользователя
   *
   * Порядок выполнения:
   * - Обновление в состоянии данных о номере телефона в форме регистрации пользователя
   * - Обращение к [действию]{@link RegistrationActions#ResetPhoneConfirmation} для сброса факта подтверждения номера телефона (код из СМС)
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.ChangePhone)
  public changePhone(ctx: StateContext<IRegistrationState>,
                     action: RegistrationActions.ChangePhone) {
    const { phone } = this.store.selectSnapshot(ProfileState.form)?.confirmation;

    ctx.dispatch(new UpdateFormValue({
      path: 'profile.form',
      value: {
        ...this.store.selectSnapshot(ProfileState.form),
        phone,
        confirmation: {
          ...this.store.selectSnapshot(ProfileState.form)?.confirmation,
          code: '',
          confirmed: undefined,
          inputCount: 0,
          sendCount: 0,
          refresh: new Date(+new Date() + 60 * 1000).toISOString(),
        },
      },
    }));

    ctx.dispatch(new RegistrationActions.ResetPhoneConfirmation());
  }

  /**
   * Действие для подтверждения введенного телефона при изменения телефона пользователя
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link RegistrationService#verifyCode}
   * - В случае успешного ответа - передаем [данные]{@link RegistrationActions#ConfirmPhoneSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link RegistrationActions#ConfirmPhoneFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.ConfirmPhone)
  public confirmPhone(ctx: StateContext<IRegistrationState>,
                      action: RegistrationActions.ConfirmPhone) {
    const { verificationCodeId } = this.store.selectSnapshot(ProfileState.form)?.confirmation;

    return this.service.verifyCode(
      verificationCodeId,
      {
        id: verificationCodeId,
        type: 'PhoneVerification',
        attributes: {
          code: action.payload,
          webbankirCrossId: this.store.selectSnapshot(EventsState.webbankirCrossId),
        },
      },
    ).pipe(
      tap((response) => ctx.dispatch(new RegistrationActions.ConfirmPhoneSuccess(response.data))),
      catchError((err, caught) => {
        this.catchSentryError('RegistrationActions.ConfirmPhoneFail', err);
        return ctx.dispatch(
          new RegistrationActions.ConfirmPhoneFail(err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие для неуспешного подтверждения введенного телефона при изменения телефона пользователя
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.ConfirmPhoneFail)
  public confirmPhoneFail(ctx: StateContext<IRegistrationState>,
                          action: RegistrationActions.ConfirmPhoneFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешного ввода смс-кода формы регистрации
   *
   * Порядок выполнения:
   * - Обновление в состоянии данных о наименовании шага регистрации
   * - Обновление даты подтверждения в форме регистрации пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.ConfirmPhoneSuccess)
  public confirmPhoneSuccess(ctx: StateContext<IRegistrationState>,
                             action: RegistrationActions.ConfirmPhoneSuccess) {
    ctx.setState(
      patch({
        currentStep: '2',
      }),
    );

    ctx.dispatch(new UpdateFormValue({
      path: 'profile.form',
      value: {
        ...this.store.selectSnapshot(ProfileState.form)?.confirmation,
        confirmed: new Date().toISOString(),
      },
      propertyPath: 'confirmation',
    }));

    if (!ctx.getState().esiaUsed) {
      ctx.dispatch(new RegistrationActions.MobileID());
    }
  }

  /**
   * Действие для предзаполнения данных регистрации на основании MobileID
   * (получение данных клиента по установочным данным от оператора сотовой связи)
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link RegistrationService#mobileId}
   * - В случае успешного ответа - передаем [данные]{@link RegistrationActions#MobileIDSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link RegistrationActions#MobileIDFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.MobileID)
  public mobileID(ctx: StateContext<IRegistrationState>,
                  action: RegistrationActions.MobileID) {
    const { phone, fio, bDay } = this.store.selectSnapshot(ProfileState.form);

    return this.service.mobileId({
      type: 'PersonalData',
      attributes: {
        phone: '+7' + phone,
        birthDate: bDay.replace(/(\d\d).(\d\d).(\d\d\d\d)/g, `$3-$2-$1`),
        name: fio.split(' ')[1],
        surname: fio.split(' ')[0],
        patronymic: fio.split(' ')[2],
        webbankirCrossId: this.store.selectSnapshot(EventsState.webbankirCrossId),
      },
    }).pipe(
      timeout(5000),
      tap((response) => ctx.dispatch(new RegistrationActions.MobileIDSuccess(response.data.attributes))),
      catchError((err, caught) => {
        this.catchSentryError('RegistrationActions.MobileIDFail', err);
        return ctx.dispatch(
          new RegistrationActions.MobileIDFail(err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие для неуспешного предзаполнения данных регистрации на основании MobileID
   * (получение данных клиента по установочным данным от оператора сотовой связи)
   *
   * Порядок выполнения:
   * - Обращение к [действию]{@link EventsActions#Event} для обновления данных MobileID
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.MobileIDFail)
  public mobileIDFail(ctx: StateContext<IRegistrationState>,
                      action: RegistrationActions.MobileIDFail) {
    ctx.dispatch(new EventsActions.Event({
      name: EventName.MobileID,
      category: CategoryName.MobileID,
      success: false,
    }));
    this.catchError(action.payload, false);
  }

  /**
   * Действие для успешного предзаполнения данных регистрации на основании MobileID
   * (получение данных клиента по установочным данным от оператора сотовой связи)
   *
   * Порядок выполнения:
   * - Обновление данных пользователя в форме регистрации на основании полученных данных по MobileID
   * - Обращение к [действию]{@link EventsActions#Event} для обновления данных MobileID
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.MobileIDSuccess)
  public mobileIDSuccess(ctx: StateContext<IRegistrationState>,
                         action: RegistrationActions.MobileIDSuccess) {
    ctx.dispatch(new UpdateFormValue({
      path: 'profile.form',
      value: {
        ...this.store.selectSnapshot(ProfileState.form),
        gender: action.payload?.gender,
        email: action.payload?.email,
        passport: `${action.payload?.passport?.series}${action.payload.passport?.number}`,
        passportIssuedBy: action.payload?.passport?.issuedBy,
        passportDivisionCode: action.payload?.passport?.divisionCode,
        passportDateOfIssue: action.payload?.passport?.issuedAt?.replace(/(\d\d\d\d)-(\d\d)-(\d\d)/g, `$3.$2.$1`),
      },
    }));

    ctx.dispatch(new EventsActions.Event({
      name: EventName.MobileID,
      category: CategoryName.MobileID,
      success: true,
    }));
  }

  /**
   * Действие для сброса факта подтверждения номера телефона (код из СМС)
   *
   * Порядок выполнения:
   * - Обновление в состоянии данных о подтверждении номера телефона в форме регистрации пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.ResetPhoneConfirmation)
  public resetPhoneConfiration(ctx: StateContext<IRegistrationState>,
                               action: RegistrationActions.ResetPhoneConfirmation) {
    ctx.setState(
      patch({
        currentStep: 'confirmation',
      }),
    );

    ctx.dispatch(new UpdateFormValue({
      path: 'profile.form',
      value: {
        ...this.store.selectSnapshot(ProfileState.form)?.confirmation,
        code: '',
        confirmed: undefined,
        inputCount: 0,
        sendCount: 0,
        refresh: new Date().toISOString(),
      },
      propertyPath: 'confirmation',
    }));
  }

  /**
   * Действие для отправки дополнительных полей при регистрации
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link RegistrationService#fillAdditional}
   * - В случае успешного ответа - передаем [данные]{@link RegistrationActions#FillAdditionalSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link RegistrationActions#FillAdditionalFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.FillAdditional)
  public fillAdditional(ctx: StateContext<IRegistrationState>,
                        action: RegistrationActions.FillAdditional) {
    const { snilsOrInn, inn, snils } = this.store.selectSnapshot(ProfileState.form);

    return this.service.fillAdditional({
      controlAnswer: 'КонтрольноеСловоНеЗадано',
      controlQuestion: 'Данное поле было удалено из формы заявителя',
      controlQuestionType: 3,
      snilsOrInn: snilsOrInn || inn || snils,
    }).pipe(
      tap(() => ctx.dispatch(new RegistrationActions.FillAdditionalSuccess())),
      catchError((err, caught) => {
        this.catchSentryError('RegistrationActions.FillAdditionalFail', err);
        return ctx.dispatch(
          new RegistrationActions.FillAdditionalFail(err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие для неуспешной отправки дополнительных полей при регистрации
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.FillAdditionalFail)
  public fillAdditionalFail(ctx: StateContext<IRegistrationState>,
                            action: RegistrationActions.FillAdditionalFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешной отправки дополнительных полей при регистрации
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.FillAdditionalSuccess)
  public fillAdditionalSuccess(ctx: StateContext<IRegistrationState>,
                               action: RegistrationActions.FillAdditionalSuccess) {

  }

  /**
   * Действие для сброса состояния к значению по умолчанию
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.Reset)
  public reset(ctx: StateContext<IRegistrationState>,
               action: RegistrationActions.Reset) {
    ctx.setState(REGISTRATION_STATE_DEFAULTS);
  }

  /**
   * Метод формирующий объект интерфейса {@link IRegistrationApplicationDto} содержащий введенные пользователем данные формы регистрации
   * возвращает объект интерфейса {@link IRegistrationApplicationDto}
   *
   * @param state состояние регистрации пользователя
   * @param webbankirCrossId WEBBANKIR Cross ID
   */
  private static buildForm(
    state: IRegistrationState,
    form: IProfileForm,
    webbankirCrossId: string,
    ): IRegistrationApplicationDto {

    const stepOne = this.buildStepOne(form);
    const stepTwo = this.buildStepTwo(form);
    const stepThree = this.buildStepThree(form);

    return {
      additionalPhone: null,
      additionalPhoneOwner: null,
      beneficial: {
        firstName: null,
        lastName: null,
        middleName: null,
        passport: null,
        passportDateOfIssue: null,
        passportDivisionCode: null,
        state: false,
      },
      brand: BRAND_WEBBANKIR,
      hasPreviousConviction: false,
      publicity: {
        benefitsOfAnotherPerson: false,
        internationalOrganizationName: null,
        internationalOrganizationOwnerIsMe: false,
        internationalOrganizationState: false,
        officialName: null,
        officialOwnerIsMe: false,
        officialRussiaName: null,
        officialRussiaOwnerIsMe: false,
        officialRussiaState: false,
        officialState: false,
      },
      referer: CookieService.referrer,
      smsCode: undefined,
      sourceOfInformation: null,
      webbankirCrossId,
      final: 1,
      storageKey: state.esiaKey,
      ...stepOne,
      ...stepTwo,
      ...stepThree,
      sds: [
        ...stepOne.sds,
        ...stepThree.sds,
      ],
    };
  }

  /**
   * Метод формирующий объект интерфейса {@link IRegistrationS1Dto} содержащий введенные пользователем данные первого шага регистрации
   * возвращает объект интерфейса {@link IRegistrationS1Dto}
   * @param state состояние регистрации пользователя
   */
  private static buildStepOne(form: IProfileForm): IRegistrationS1Dto {
    const {
      bDay,
      fio,
      phone,
    } = form;
    const { signAgreement, shortConsents } = form.consents;
    return {
      bDay,
      lastName: fio?.split(' ')[0],
      firstName: fio?.split(' ')[1],
      middleName: fio?.split(' ')[2],
      mobilePhone: '7' + phone,
      sds: [
        this.buildConsent(signAgreement),
        this.buildConsent(shortConsents),
      ],
    };
  }

  /**
   * Метод формирующий объект интерфейса {@link IRegistrationS2Dto} содержащий введенные пользователем данные второго шага регистрации
   * возвращает объект интерфейса {@link IRegistrationS2Dto}
   * @param state состояние регистрации пользователя
   */
  private static buildStepTwo(form: IProfileForm): IRegistrationS2Dto {
    const {
      email,
      bPlace,
      gender,
      passport,
      passportDivisionCode,
      passportDateOfIssue,
      passportIssuedBy,
      passportEsiaStatus,
      address,
      doNotHaveStreet,
      phone,
      addressRegDate
    } = form;

    return {
      email,
      bPlace,
      gender,
      passport,
      passportDivisionCode,
      passportDateOfIssue,
      passportIssuedBy,
      passportEsiaStatus,
      address: {
        postalCode: address?.value?.postal_code,
        region: address?.value?.region_kladr_id,
        city: address?.value?.city,
        settlement: address?.value?.settlement,
        street: address?.value?.street_type === 'ул' ? address?.value?.street : address?.value?.street_with_type,
        doNotHaveStreet: doNotHaveStreet || false,
        house: address?.value?.house,
        geoLat: address?.value?.geo_lat,
        geoLon: address?.value?.geo_lon,
        housing: address?.value?.block_type === 'к' ? address?.value?.block?.split(' стр ')?.[0] : undefined,
        building: address?.value?.block_type === 'стр' ? address?.value?.block : address?.value?.block?.split(' стр ')?.[1],
        flat: address?.value?.flat,
      },
      addressFiases: {
        value: address?.label,
        region_fias_id: address?.value?.region_fias_id,
        city_fias_id: address?.value?.city_fias_id,
        settlement_fias_id: address?.value?.settlement_fias_id,
        street_fias_id: address?.value?.street_fias_id,
        house_fias_id: address?.value?.house_fias_id,
      },
      mobilePhone: '7' + phone,
      addressRegDate: addressRegDate,
    };
  }


  /**
   * Метод формирующий объект интерфейса {@link IRegistrationS3Dto} содержащий введенные пользователем данные третьего шага регистрации
   * возвращает объект интерфейса {@link IRegistrationS3Dto}
   * @param state состояние регистрации пользователя
   */
  private static buildStepThree(form: IProfileForm): IRegistrationS3Dto {
    const {
      snilsOrInn,
      inn, snils,
      educationType,
      maritalStatus,
      loansPurpose,
      mandatoryPaymentsAmount,
      hasChildren,
      numberOfChildren,
      workAddress,
      workFullName, // TODO empty
      workInn, // TODO empty
      workName,
      workNumberOfEmployees,
      workPeriod,
      workPhone, // TODO empty
      workPosition, // TODO empty
      workSalary,
      workScope,
      workType,
      typeOfEmployment,
      phone,
      workAdditionalIncome,
      workAdditionalIncomeDefault,
      workOgrnip,
      workRegDate
    } = form;

    const { consents, edsAgreement } = form.consents;

    return {
      snilsOrInn,
      inn: inn || (snilsOrInn?.length === 11 ? '' : snilsOrInn),
      snils: snilsOrInn?.length !== 11 ? snils || '' : snilsOrInn,
      educationType: educationType?.value,
      maritalStatus: maritalStatus?.value,
      loansPurpose: loansPurpose?.value,
      mandatoryPaymentsAmount,
      children: hasChildren,
      numberOfChildren: hasChildren ? numberOfChildren : null,
      workFullName,
      workINN: workInn,
      workName,
      workNumberOfEmployees: workNumberOfEmployees?.value,
      workPeriod: workPeriod?.value,
      workPhone,
      workPosition,
      workSalary,
      workAdditionalIncome,
      workAdditionalIncomeDefault,
      workScope: workScope?.value,
      workType,
      workRegDate: workRegDate,
      workOgrnip,
      typeOfEmployment: typeOfEmployment?.value,
      workAddress: {
        postalCode: workAddress?.value?.postal_code,
        region: workAddress?.value?.region_kladr_id,
        city: workAddress?.value?.city,
        settlement: workAddress?.value?.settlement,
        street: workAddress?.value?.street,
        doNotHaveStreet: !!workAddress?.value?.street || false,
        house: workAddress?.value?.house,
        geoLat: workAddress?.value?.geo_lat,
        geoLon: workAddress?.value?.geo_lon,
        housing: workAddress?.value?.block_type === 'к' ? workAddress?.value?.block?.split(' стр ')?.[0] : undefined,
        building: workAddress?.value?.block_type === 'стр' ? workAddress?.value?.block : workAddress?.value?.block?.split(' стр ')?.[1],
        flat: workAddress?.value?.flat,
      },
      workAddressFiases: {
        value: workAddress?.label,
        region_fias_id: workAddress?.value?.region_fias_id,
        city_fias_id: workAddress?.value?.city_fias_id,
        settlement_fias_id: workAddress?.value?.settlement_fias_id,
        street_fias_id: workAddress?.value?.street_fias_id,
        house_fias_id: workAddress?.value?.house_fias_id,
      },
      sds: [
        this.buildConsent(consents),
        this.buildConsent(edsAgreement),
      ],
      mobilePhone: '7' + phone,
    };
  }

  /**
   * Метод конвертации объекта {@link IConsent} в {@link ISdsDto}
   * @param consent объект интерфейса {@link IConsent} содержащий согласия пользователя с документами
   * @private
   */
  private static buildConsent(consent: IConsent): ISdsDto {
    return consent ? {
      code: consent.signature,
      validFor: consent.type,
      ...consent.deviceInfo,
    } : undefined;
  }

  /**
   * Метод преобразования строчного URL в гиперссылку
   * @param url адрес ссылки
   */
  public convertStringToLink(url: string): Element {
    const link = this.window?.document.createElement('a');
    link.setAttribute('href', `${url}`);
    link.textContent = url;
    return link;
  }

  /**
   * Действие для получения данных от IDP ESIA с целью осуществления авторизации или предзаполнения формы регистрации
   * (в случае, если пользователь был ранее не зарегистрирован в системе)
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link RegistrationService#esiaData}
   * - В случае успешного ответа - передаем [данные]{@link RegistrationActions#EsiaSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link RegistrationActions#EsiaFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.Esia)
  public async esia(ctx: StateContext<IRegistrationState>,
                    action: RegistrationActions.Esia) {
    return this.service.esiaData(action.payload).pipe(
      tap((response) => {
        if (isLoginDataResponse(response)) {
          ctx.dispatch(new AuthActions.EsiaSuccess(response.data, action.payload));
          ctx.dispatch(new Navigate(
            ['/cabinet'],
          ));
        } else {
          ctx.dispatch(new RegistrationActions.EsiaSuccess(response, action.payload));
        }
      }),
      catchError((err, caught) => {
        this.catchSentryError('RegistrationActions.LoanFail', err);
        return ctx.dispatch(
          new RegistrationActions.EsiaFail(err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие для неуспешного получения данных от IDP ESIA с целью осуществления авторизации или предзаполнения формы регистрации
   * (в случае, если пользователь был ранее не зарегистрирован в системе)
   *
   * Порядок выполнения:
   * - Вызов всплывающего окна {@link ToastService#notification} с информацией об ошибке
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.EsiaFail)
  public esiaFail(ctx: StateContext<IRegistrationState>,
                  action: RegistrationActions.EsiaFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешного получения данных от IDP ESIA с целью осуществления авторизации или предзаполнения формы регистрации
   * (в случае, если пользователь был ранее не зарегистрирован в системе)
   *
   * Порядок выполнения:
   * - Обновление в состоянии данных о IDP ESIA
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.EsiaSuccess)
  public esiaSuccess(ctx: StateContext<IRegistrationState>,
                     action: RegistrationActions.EsiaSuccess) {
    const step = ctx.getState().currentStep;

    ctx.patchState({
      esiaUsed: true,
      esiaKey: action.key,
      currentStep: step,
    });

    ctx.dispatch(new RegistrationActions.Fill(action.payload, step !== '1'));
  }

  /**
   * Метод преобразовывающий инн/снилс в валидное значения
   *
   * Порядок выполнения:
   * - Преобразуем иходное значение, оставляя в нем только числа
   * - Если тип inn:
   *   - Если длина символов 10 или 12, возвращаем преобразованное значение инн
   *   - Иначе возвращаем null
   * - Если тип shils:
   *   - Если длина символов 11, возвращаем преобразованное значение снилс
   *   - Иначе возвращаем null
   * - Если тип не передан:
   *   - Вызываем метов {@link normalizeInnOrSnils} с параметром type=snils, если значение есть, возвращаем его
   *   - Иначе вызываем метов {@link normalizeInnOrSnils} с параметром type=inn и возвраащем его значение
   *
   * @param value Исходное значение
   * @param type Тип значения (ИНН/СНИЛС)
   */
  private normalizeInnOrSnils(value: string, type?: 'inn' | 'snils'): string {
    const normalizedValue = value?.replace(/[^\d]/g, '');
    if (type === 'inn') {
      return normalizedValue?.length === 10 || normalizedValue?.length === 12 ? normalizedValue : null;
    }

    if (type === 'snils') {
      return normalizedValue?.length === 11 ? normalizedValue : null;
    }

    return this.normalizeInnOrSnils(normalizedValue, 'snils') || this.normalizeInnOrSnils(normalizedValue, 'inn');
  }

  /**
   * Порядок выполнения:
   *  - получаем данные из формы регистрации
   *  - добавляем необходимые поля из формы в объект customer
   *  - обновляем данные в хранилище {@link EventsActions#Mindbox}
   *  - обновляем данные в хранилище {@link EventsActions#Event}
   * @param ctx
   * @param action
   */

  @Action(RegistrationActions.RegistrationSngNationalityComplete)
  public registrationSngNationalityComplete(
    ctx: StateContext<IRegistrationState>,
    action: RegistrationActions.RegistrationSngNationalityComplete,
  ) {
    const profileForm = this.store.selectSnapshot(ProfileState.form);
    const customer: Partial<ICustomerProfile> = {
      email: profileForm?.email,
      mobilePhone: '7' + profileForm?.phone,
    };
    ctx.dispatch(
      new EventsActions.Mindbox(MindboxOperation.PressedButtonSNG, {
        customer,
      }),
    );
    ctx.dispatch(
      new EventsActions.Event({
        name: EventName.CancelingBareBasket,
        category: CategoryName.Registration,
        payload: {
          phone: profileForm?.phone ? '7' + profileForm?.phone : null,
        },
      }),
    );
  }

  /**
   * Действие отправка телефона для службы поддержки
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SendPhone)
  public sendPhone(ctx: StateContext<IRegistrationState>,
                action: RegistrationActions.SendPhone) {
    const form = this.store.selectSnapshot(ProfileState.form);
    const step = ctx.getState().currentStep;
    return this.service.sendPhone('7' + form?.phone, form?.fio, +step).pipe(
      tap(() => ctx.dispatch(new RegistrationActions.SendPhoneSuccess())),
      catchError((err, caught) => {
        return ctx.dispatch(
          new RegistrationActions.SendPhoneFail(err.error?.errors),
          );
        }),
      );
    }

    /**
   * Действие для успешной отправки телефона для службы поддержки
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SendPhoneSuccess)
  public sendPhoneSuccess(ctx: StateContext<IRegistrationState>,
                action: RegistrationActions.SendPhoneSuccess) {
  }

    /**
   * Действие для неуспешной отправки телефона для службы поддержки
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.SendPhoneFail)
  public sendPhoneFail(ctx: StateContext<IRegistrationState>,
                action: RegistrationActions.SendPhoneFail) {
                  this.catchError(action.payload, false);
  }

   /**
   * Действие для отправки данных для прохождения УПРИД
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.Identify, { cancelUncompleted: true })
  public identify( ctx: StateContext<IRegistrationState>, action: RegistrationActions.Identify) {
    const profileForm = this.store.selectSnapshot(ProfileState.form);
    const fio = profileForm.fio?.split(' ');

    const data: IRegistrationIdentification = {
      type: 'applications',
      attributes: {
        birthDate: dayjs(profileForm.bDay, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        mobilePhone: '7' + profileForm.phone,
        name: fio?.[1],
        patronymic: fio?.[2],
        surname: fio?.[0],
        passportNumber: profileForm.passport.slice(-6),
        passportSeries: profileForm.passport.slice(0, 4),
        passportIssueDate: dayjs(profileForm.passportDateOfIssue, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        passportIssuedBy: profileForm.passportIssuedBy,
        birthPlace: profileForm.bPlace,
        clientId: this.store.selectSnapshot(EventsState.webbankirCrossId),
      }
    }

    return this.service.identification(data).pipe(
      tap(() => {
        ctx.dispatch(new RegistrationActions.IdentifySuccess());
      }),
      catchError((err, caught) => {
        this.catchSentryError('RegistrationActions.IdentifyFail', err);
        return ctx.dispatch(
          new RegistrationActions.IdentifyFail(err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие для успешной отправки данных для прохождения УПРИД
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.IdentifySuccess)
  public identifySuccess(ctx: StateContext<IRegistrationState>,
                     action: RegistrationActions.IdentifySuccess) {
  }

  /**
   * Действие для неуспешной отправки данных для прохождения УПРИД
   *
   * @param ctx
   * @param action
   */
  @Action(RegistrationActions.IdentifyFail)
  public identifyFail(ctx: StateContext<IRegistrationState>,
                     action: RegistrationActions.IdentifyFail) {
  }
}
